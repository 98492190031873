import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import "../../assets/styles/css/HomePage.css";

function AccordionQuestion() {
  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div className="accordion-section">
      <Accordion flush open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">
            Kapan waktu yang tepat untuk melakukan pemesanan undangan?
          </AccordionHeader>
          <AccordionBody accordionId="1">
            Waktu yang tepat untuk melakukan pemesanan adalah 2 bulan sebelum
            acara pernikahan.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">
            Bagaimana cara melakukan pemesanan undangan?
          </AccordionHeader>
          <AccordionBody accordionId="2">
            Untuk pemesanan bisa langsung tekan icon Whatsapp untuk di desktop,
            sedangkan di mobile bisa tekan Whatsapp yang ada di navigasi bar.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="3">
            Apakah kata-kata yang ada di undangan bisa diganti ?
          </AccordionHeader>
          <AccordionBody accordionId="3">
            Tentu saja bisa, setelah kamu melakukan pemesanan akan diberikan
            form untuk data undanganmu.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="4">
            Apakah bisa custom nama tamu ?
          </AccordionHeader>
          <AccordionBody accordionId="4">
            Tentu bisa, kami telah menyediakan fitur custom nama tamu untuk
            undanganmu.
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="5">
            Berapa lama proses pengerjaan ?
          </AccordionHeader>
          <AccordionBody accordionId="5">
            Setelah kami melakukan meeting, kami langsung melakukan proses
            pengerjaan. Undangan kamu bisa selesai 3-4 hari.
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default AccordionQuestion;
