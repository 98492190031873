import "../../assets/styles/css/Whatsapp.css";
import { FaWhatsapp } from "react-icons/fa";

function Whatsapp() {
  return (
    <section>
      <div className="whatsapp shadow d-none d-md-block">
        <div className="whatsapp-logo">
          <a
            href="https://wa.wizard.id/e21b63"
            target="_blank"
            rel="noreferrer"
          >
            <FaWhatsapp />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Whatsapp;
