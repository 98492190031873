import Navigasi from "../components/Navigasi/Navigasi";
import MainFeatures from "../components/MainFeatures/MainFeatures";
import Footer from "../components/Footer/Footer";
import NavbarBottom from "../components/NavbarBottom/NavbarBottom";
import Whatsapp from "../components/Whatsapp/Whatsapp";

function Features() {
  return (
    <>
      <Navigasi />
      <MainFeatures />
      <NavbarBottom />
      <Whatsapp />
      <Footer />
    </>
  );
}

export default Features;
