import React from "react";

function CardCaption(props) {
  const {
    handler,
    id,
    firstLine,
    secondLine,
    thirdLine,
    fourLine,
    fiveLine,
    sixLine,
  } = props;

  return (
    <>
      <div className="col-8 col-md-5 card p-0 rounded-4 mb-5">
        <div className="card-body">
          <h5 className="fw-bold heading-caption mb-4">Caption {id}</h5>
          <div className="mb-5">
            <p>{firstLine}</p>
            <p>{secondLine}</p>
            <p>{thirdLine}</p>
            <p>{fourLine}</p>
            <p>{fiveLine}</p>
            <p>{sixLine}</p>
          </div>
          <div className="button-copy d-flex justify-content-center">
            <button className="fw-bold btn btn-copy" onClick={handler}>
              Copy Text
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardCaption;
