import Footer from "../components/Footer/Footer";
import MainCatalog from "../components/MainCatalog/MainCatalog";
import NavbarBottom from "../components/NavbarBottom/NavbarBottom";
import Navigasi from "../components/Navigasi/Navigasi";
import Whatsapp from "../components/Whatsapp/Whatsapp";

function Catalog() {
  return (
    <>
      <Navigasi />
      <MainCatalog />
      <NavbarBottom />
      <Whatsapp />
      <Footer />
    </>
  );
}

export default Catalog;
