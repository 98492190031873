// Import Component
import CardCatalog from "./CardCatalog";
import "../../assets/styles/css/MainCatalog.css";

// Import Images
import mockupSatu from "../../assets/img/mock_tema_satu.jpg";
import mockupDua from "../../assets/img/mock_tema_dua.jpg";
import mockupTiga from "../../assets/img/mock_tema_tiga.jpg";
import mockupEmpat from "../../assets/img/mock_tema_empat.jpg";
import mockupLima from "../../assets/img/Mock_tema_lima.jpg";
import mockupEnam from "../../assets/img/mock_tema_tujuh.jpg";

function MainCatalog() {
  const cards = [
    {
      image: mockupSatu,
      name: "ROSEWOOD",
      basicPrice: "175K",
      basicLink: "https://undankinid.com/rosewood-basic/",
      premiumPrice: "235K",
      premiumLink: "https://undankinid.com/rosewood-premium/",
    },
    {
      image: mockupDua,
      name: "MAHOGANI",
      basicPrice: "175K",
      basicLink: "https://undankinid.com/mahogani-basic/",
      premiumPrice: "235K",
      premiumLink: "https://undankinid.com/mahogani-premium/",
    },
    {
      image: mockupTiga,
      name: "URSAMAYOR",
      basicPrice: "175K",
      basicLink: "https://undankinid.com/ursamayor-basic/",
      premiumPrice: "235K",
      premiumLink: "https://undankinid.com/ursamayor-premium/",
    },
    {
      image: mockupEmpat,
      name: "MAGELLAN",
      basicPrice: "175K",
      basicLink: "https://undankinid.com/magellan-basic/",
      premiumPrice: "235K",
      premiumLink: "https://undankinid.com/magellan-premium/",
    },
    {
      image: mockupLima,
      name: "SAGEPIU",
      basicPrice: "175K",
      basicLink: "https://undankinid.com/sagepiu-basic/",
      premiumPrice: "235K",
      premiumLink: "https://undankinid.com/sagepiu-premium/",
    },
    {
      image: mockupEnam,
      name: "SUPERDUST",
      basicPrice: "Not Available",
      basicLink: "#",
      premiumPrice: "275K",
      premiumLink: "https://undankinid.com/superdust-premium/",
    },
  ];

  return (
    <section className="catalog" id="catalog">
      <div className="container">
        <div className="row section-heading mb-5 justify-content-center">
          <div className="col-11 col-md-12">
            <div className="heading-wrapper">
              <h1 className="mb-4">Catalog</h1>
              <p className="mb-2">
                Kami memiliki beberapa tema undangan digital yang simple,
                minimalis dan elegant. Pilihlah tema keinginananmu untuk disebar
                kepada para tamu.
              </p>
              <p>*Tekan tulisan yang bergaris bawah untuk melihat demo.</p>
            </div>
          </div>
        </div>
        <div className="row section-catalog justify-content-center">
          {cards.map((card, index) => {
            return (
              <CardCatalog
                key={index}
                image={card.image}
                name={card.name}
                basicPrice={card.basicPrice}
                basicLink={card.basicLink}
                premiumPrice={card.premiumPrice}
                premiumLink={card.premiumLink}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default MainCatalog;
