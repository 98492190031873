import "../../assets/styles/css/MainFeatures.css";

function MainFeatures() {
  const premium = [
    "Custom Nama Tamu",
    "Quote",
    "Informasi Sosial Media",
    "Hitung Mundur",
    "Save The Date",
    "Maps",
    "Cerita Kisah",
    "20 Foto",
    "1 Video",
    "Amplop Digital",
    "Reservasi & Ucapan",
    "Request Music",
    "Request Warna Background",
    "Revisi 3 kali",
    "Dashboard Undangan",
  ];

  const basic = [
    "Custom Nama Tamu",
    "Quote",
    "Informasi Sosial Media",
    "Maps",
    "10 Foto",
    "Amplop Digital",
    "Reservasi & Ucapan",
    "Request Music",
    "Revisi 2 kali",
    "Dashboard Undangan",
  ];

  return (
    <section className="features">
      <div className="container">
        <div className="row section-heading justify-content-center mb-5">
          <div className="col-11 col-md-12">
            <div className="heading-wrapper">
              <h1 className="mb-4">Features</h1>
              <p>
                Kami menyediakan dua paket untuk undangan, silahkan pilih paket
                undangan yang akan disebarkan kepada tamu spesial anda.
              </p>
            </div>
          </div>
        </div>
        <div className="row section-features justify-content-evenly">
          <div className="col-11 col-md-4 wrapper-features border p-4 rounded-4 mb-3 mb-md-0">
            <div className="heading mb-4">
              <h1>PREMIUM</h1>
            </div>
            <div className="list-features">
              {premium.map((list, index) => {
                return (
                  <p className="mb-1" key={index}>
                    - {list}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="col-11 col-md-4 wrapper-features border p-4 rounded-4">
            <div className="heading mb-4">
              <h1>BASIC</h1>
            </div>
            <div className="list-features">
              {basic.map((list, index) => {
                return (
                  <p className="mb-1" key={index}>
                    - {list}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainFeatures;
