import CardService from "./CardService";
import "../../assets/styles/css/HomePage.css";

function Service() {
  const services = [
    {
      title: "Minimalis Desain",
      description:
        "Desain undangan yang elegan dan minimalis dapat diakses melalui smarthphone, tablet, dan desktop",
    },
    {
      title: "Dashboard Undangan",
      description:
        "Anda dapat melihat jumlah tamu, daftar kehadiran tamu, ucapan dan doa dari tamu, serta dapat melakukan memodifnya",
    },
    {
      title: "Ekonomis",
      description:
        "Dengan menggunakan undangan digital, kamu dapat menghemat biaya serta dapat mengurangi penggunaan kertas",
    },
  ];

  return (
    <section>
      <div className="service">
        <div className="container">
          <div className="row gap-3 gap-md-5 justify-content-center">
            <div className="title text-center mb-4 mb-md-5">
              <h1>PELAYANAN</h1>
              <p>Kami memiliki pelayanan spesial untuk anda sebagai berikut</p>
            </div>
            {services.map((e, index) => {
              return (
                <CardService key={index} title={e.title} desc={e.description} />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;
