import { FaTiktok, FaInstagram, FaWhatsapp } from "react-icons/fa";
import "../../assets/styles/css/Footer.css";
import { Link } from "react-router-dom";

import mandiri from "../../assets/img/mandiri.png";
import gopay from "../../assets/img/gopay.png";
import ovo from "../../assets/img/ovo.png";
import dana from "../../assets/img/dana.png";

function Footer() {
  return (
    <footer className="footer-user">
      <div className="container">
        <div className="row top mb-4 justify-content-center">
          {/* Sitemap  */}
          <div className="col-5 col-md-3 sitemap">
            <div className="heading">
              <h5>Sitemap</h5>
            </div>
            <div className="link">
              <div className="link-wrapper">
                <Link to="/features" className="nav-link">
                  Features
                </Link>
              </div>
              <div className="link-wrapper">
                <Link to="/catalog" className="nav-link">
                  Catalog
                </Link>
              </div>
              <div className="link-wrapper">
                <a href="/#question" className="nav-link">
                  FAQ
                </a>
              </div>
              <div className="link-wrapper">
                <a href="#contact" className="nav-link">
                  Contact
                </a>
              </div>
            </div>
          </div>

          {/* Contact */}
          <div className="col-5 col-md-3 contact" id="contact">
            <div className="heading">
              <h5>Follow Us</h5>
            </div>
            <div className="section-contact">
              <div className="contact-item">
                <span>
                  <FaInstagram className="me-2 fs-5" />
                  undankin
                </span>
              </div>
              <div className="contact-item">
                <span>
                  <FaTiktok className="me-2 fs-5" />
                  undankin
                </span>
              </div>
              <div className="contact-item">
                <span>
                  <FaWhatsapp className="me-2 fs-5" />
                  +62 895-0991-2641
                </span>
              </div>
            </div>
          </div>

          {/* Payment */}
          <div className="col-10 col-md-6 payment mt-4 mt-md-0">
            <div className="heading">
              <h5>Payment</h5>
            </div>
            <div className="top-image d-flex mb-3">
              <div className="payment-logo me-3">
                <img src={mandiri} alt="logo" className="img-fluid" />
              </div>
              <div className="payment-logo">
                <img src={gopay} alt="logo" className="img-fluid" />
              </div>
            </div>
            <div className="bottom-image d-flex">
              <div className="payment-logo me-3">
                <img src={ovo} alt="logo" className="img-fluid" />
              </div>
              <div className="payment-logo">
                <img src={dana} alt="logo" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
        <div className="row bottom justify-content-center text-center">
          <div className="col-12">
            <div className="copyright">
              <p className="m-0 small">© 2022 Undankin All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
