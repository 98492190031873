import "../../assets/styles/css/HomePage.css";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Autoplay, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// Import images
import clientSatu from "../../assets/img/gambar_enam.png";
import clientDua from "../../assets/img/gambar_tiga.png";
import clientTiga from "../../assets/img/gambar_enam.png";
import clientEmpat from "../../assets/img/gambar_tiga.png";
import clientLima from "../../assets/img/gambar_enam.png";
import clientEnam from "../../assets/img/gambar_tiga.png";

function Client() {
  const clients = [
    clientSatu,
    clientDua,
    clientTiga,
    clientEmpat,
    clientLima,
    clientEnam,
  ];

  return (
    <section className="client d-none">
      <div className="container">
        <div className="row justify-content-center justify-content-md-end">
          <div className="col-11 col-md-8 section-image">
            <div className="section-image-wrapper">
              <div className="heading d-none d-md-block">
                <h1 className="mb-4">
                  OUR
                  <br />
                  CLIENTS
                </h1>
                <a
                  href="https://wa.wizard.id/636e02"
                  target="_blank"
                  rel="noreferrer"
                >
                  Let's Order
                </a>
              </div>
              <div className="heading-res mb-4 d-block d-md-none">
                <h1>OUR CLIENTS</h1>
                <a
                  href="https://wa.wizard.id/636e02"
                  target="_blank"
                  rel="noreferrer"
                >
                  Let's Order
                </a>
              </div>
              <div className="section-slider">
                <Swiper
                  breakpoints={{
                    200: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  // slidesPerView={3}
                  spaceBetween={5}
                  modules={[Autoplay, Navigation]}
                  className="mySwiper"
                >
                  {clients.map((client, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="swiper-slide-wrapper">
                          <img src={client} alt="img" className="img-fluid" />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Client;
