import React from "react";
import Navigasi from "../components/Navigasi/Navigasi";
import MainCaption from "../components/CaptionPage/MainCaption";
import NavbarBottom from "../components/NavbarBottom/NavbarBottom";
import Footer from "../components/Footer/Footer";

function Caption() {
  return (
    <>
      <Navigasi />
      <MainCaption />
      <NavbarBottom />
      <Footer />
    </>
  );
}

export default Caption;
