import CardCaption from "./CardCaption";
import copyCaption from "./dataCaption/dataCaption";

function MainCaption() {
  return (
    <section className="caption-section" id="caption">
      <div className="container">
        <div className="row section-heading mb-5 justify-content-center">
          <div className="col-11 col-md-12">
            <div className="heading-wrapper">
              <h1 className="mb-4">Caption</h1>
              <p className="mb-2">
                Kami telah menyediakan beberapa caption yang dapat Anda gunakan
                untuk mengirimkan pesan kepada tamu Anda yang istimewa.
              </p>
              <p>
                *Tekan tombol <b>Copy Text</b> untuk copy teks caption.
              </p>
            </div>
          </div>
        </div>
        <div className="row section-caption justify-content-around">
          {copyCaption.map((text, index) => {
            return (
              <CardCaption
                key={index}
                id={text.number}
                firstLine={text.firstLine}
                secondLine={text.secondLine}
                thirdLine={text.thirdLine}
                fourLine={text.fourLine}
                fiveLine={text.fiveLine}
                sixLine={text.sixLine}
                handler={text.copyText}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default MainCaption;
