import "../../assets/styles/css/HomePage.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

// import required modules
import { Autoplay, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// Import images
import mockupSatu from "../../assets/img/MockupSatu.png";
import mockupDua from "../../assets/img/MockupDua.png";
import mockupTiga from "../../assets/img/MockupTiga.png";
import mockupEmpat from "../../assets/img/MockupEmpat.png";
import mockupLima from "../../assets/img/MockupLima.png";
import mockupEnam from "../../assets/img/MockupTujuh.png";

function Collection() {
  const images = [
    mockupSatu,
    mockupDua,
    mockupTiga,
    mockupEmpat,
    mockupLima,
    mockupEnam,
  ];

  return (
    <section>
      <div className="collection">
        <div className="container">
          <div className="row title mb-4 mb-md-5 text-center">
            <h1>KOLEKSI TEMA</h1>
            <p>
              Anda dapat melihat demo tema undangan pada halaman
              <Link to="/catalog" className="text-light ms-1">
                katalog
              </Link>
            </p>
          </div>
          <div className="row card-swiper">
            <Swiper
              breakpoints={{
                200: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              navigation={true}
              loop={true}
              // slidesPerView={3}
              spaceBetween={10}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
              {images.map((image, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="swiper-slide-wrapper">
                      <img src={image} alt="mockup" className="img-fluid" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Collection;
