const copyCaption = [
  {
    number: "1",
    firstLine: "Assalamualaikum Wr.Wb,",
    secondLine:
      "Salam sejahtera bagi kita semua. Kami sangat berterima kasih jika Anda, saudara, kerabat, bersedia hadir untuk mendampingi dan memberikan doa restu kepada anak-anak kami yang akan memulai lembaran baru dalam perjalanan hidup mereka. Anda dapat menemukan undangan resmi kami di sini: ",
    thirdLine: "*LINK UNDANGAN*",
    fourLine: "",
    fiveLine: "With Love,",
    sixLine: "*NAMA PASANGAN*",
    copyText: () => {
      navigator.clipboard.writeText(`Assalamualaikum Wr.Wb,

Salam sejahtera bagi kita semua. Kami sangat berterima kasih jika Anda, saudara, kerabat, bersedia hadir untuk mendampingi dan memberikan doa restu kepada anak-anak kami yang akan memulai lembaran baru dalam perjalanan hidup mereka.

Anda dapat menemukan undangan resmi kami di sini: (LINK UNDANGAN).

With Love,

(NAMA PASANGAN)`);
    },
  },
  {
    number: "2",
    firstLine: "Assalamualaikum Wr.Wb,",
    secondLine:
      "Segala sesuatu diwaktu-Nya memiliki keindahannya. Tuhan telah menyatukan kami dalam ikatan pernikahan yang suci. Dengan hormat, kami ingin mengundang Anda untuk bergabung dalam momen sakral kami yang istimewa.",
    thirdLine: "*LINK UNDANGAN*",
    fourLine: "",
    fiveLine: "Salam hangat",
    sixLine: "*NAMA PASANGAN*",
    copyText: () => {
      navigator.clipboard.writeText(`Assalamualaikum Wr.Wb,

Segala sesuatu diwaktu-Nya memiliki keindahannya. Tuhan telah menyatukan kami dalam ikatan pernikahan yang suci. Dengan hormat, kami ingin mengundang Anda untuk bergabung dalam momen sakral kami yang istimewa.

*LINK UNDANGAN*

Salam hangat

*NAMA PASANGAN*`);
    },
  },
  {
    number: "3",
    firstLine: "Assalamualaikum Warahmatullahi Wabarakatuh,",
    secondLine:
      "Kepada seluruh rekan, kami dengan senang hati mengundang Anda melalui pesan singkat ini untuk hadir dalam acara pernikahan kami yang berbahagia.",
    thirdLine: "*LINK UNDANGAN*",
    fourLine:
      "Kami sangat mengharapkan kehadiran Anda dalam momen istimewa ini. Terima kasih atas waktu dan perhatiannya.",
    fiveLine: "Wassalamu'alaikum Warohmatullahi Wabarokatuh,",
    sixLine: "*NAMA PASANGAN*",
    copyText: () => {
      navigator.clipboard.writeText(`Assalamualaikum Warahmatullahi Wabarakatuh,

Kepada seluruh rekan, kami dengan senang hati mengundang Anda melalui pesan singkat ini untuk hadir dalam acara pernikahan kami yang berbahagia.

*LINK UNDANGAN*

Kami sangat mengharapkan kehadiran Anda dalam momen istimewa ini. Terima kasih atas waktu dan perhatiannya.

Wassalamu'alaikum Warohmatullahi Wabarokatuh,

*NAMA PASANGAN*`);
    },
  },
  {
    number: "4",
    firstLine:
      "Tuhan membuat segala sesuatu indah pada waktunya. Indah ketika Dia mempertemukan, ketika Dia menumbuhkan kasih, dan ketika Dia mempersatukan kami dalam pernikahan kudus. Kami mengundan Anda ke acara pernikahan kami:",
    secondLine: "*LINK UNDANGAN*",
    thirdLine:
      "Terima kasih atas waktu dan perhatian Anda. Kami sangat mengharapkan kehadiran Anda pada momen istimewa ini.",
    fourLine: "*NAMA PASANGAN*",
    fiveLine: "",
    sixLine: "",
    copyText: () => {
      navigator.clipboard
        .writeText(`Tuhan membuat segala sesuatu indah pada waktunya. Indah ketika Dia mempertemukan, ketika Dia menumbuhkan kasih, dan ketika Dia mempersatukan kami dalam pernikahan kudus. Kami mengundan Anda ke acara pernikahan kami:

*LINK UNDANGAN*

Terima kasih atas waktu dan perhatian Anda. Kami sangat mengharapkan kehadiran Anda pada momen istimewa ini.

*NAMA PASANGAN*`);
    },
  },
  {
    number: "5",
    firstLine:
      "Dua kehidupan sedang bersiap, menapaki satu jalan, seperti yang dipimpin oleh Tuhan.",
    secondLine:
      "Bersama ini kami mengundang Anda untuk berbagi kebahagiaan di pernikahan kami:",
    thirdLine: "*LINK UNDANGAN*",
    fourLine:
      "Besar harapan kami untuk kehadiran Saudara/Saudari sekalian untuk memberikan doa restu untuk pemberkatan pernikahan kami.",
    fiveLine: "With Love,",
    sixLine: "*NAMA PASANGAN*",
    copyText: () => {
      navigator.clipboard
        .writeText(`Dua kehidupan sedang bersiap, menapaki satu jalan, seperti yang dipimpin oleh Tuhan.
Bersama ini kami mengundang Anda untuk berbagi kebahagiaan di pernikahan kami:

*LINK UNDANGAN*

Besar harapan kami untuk kehadiran Saudara/Saudari sekalian untuk memberikan doa restu untuk pemberkatan pernikahan kami.

With Love,

*NAMA PASANGAN*`);
    },
  },
  {
    number: "6",
    firstLine: `Tuhan membuat segala sesuatu indah pada waktu-Nya. Indah ketika Ia mempertemukan kami. Indah ketia Ia menumbuhkan kasih di antara kami. Dan indah ketika Ia mempersatukan kami dalam sebuah ikatan pernikahan kudus.`,
    secondLine:
      "Dengan pesan ini kami mengundang anda untuk menghadiri acara pernikahan kami. Harap klik link dibawah ini untuk melihat undangan digital kami :",
    thirdLine: "*LINK UNDANGAN*",
    fourLine:
      "Besar harapan kami untuk kehadiran Saudara/Saudari sekalian untuk memberikan doa restu untuk pemberkatan pernikahan kami.",
    fiveLine: "Dari kami yang berbahagia,",
    sixLine: "*NAMA PASANGAN*",
    copyText: () => {
      navigator.clipboard
        .writeText(`Tuhan membuat segala sesuatu indah pada waktu-Nya. Indah ketika Ia mempertemukan kami. Indah ketia Ia menumbuhkan kasih di antara kami. Dan indah ketika Ia mempersatukan kami dalam sebuah ikatan pernikahan kudus.

Dengan pesan ini kami mengundang anda untuk menghadiri acara pernikahan kami. Harap klik link dibawah ini untuk melihat undangan digital kami :

*LINK UNDANGAN*

Besar harapan kami untuk kehadiran Saudara/Saudari sekalian untuk memberikan doa restu untuk pemberkatan pernikahan kami.

Dari kami yang berbahagia,

*NAMA PASANGAN*`);
    },
  },
];

export default copyCaption;
