import "../../assets/styles/css/HomePage.css";
import { Link } from "react-router-dom";

function HomePage() {
  return (
    <section>
      <div className="homepage">
        <div className="container">
          <div className="row text-center align-items-center justify-content-center">
            <div className="col-11 col-md-12">
              <div className="title mb-3">
                <h1 className="heading">UNDANKIN</h1>
              </div>
              <div className="caption mb-4">
                <p>
                  Dengan Undankin kamu dapat menyebarkan undangan pernikahanmu
                  <br className="d-none d-md-block" />
                  dengan praktis dan tanpa batas <b>Ruang</b> dan <b>Waktu</b>
                </p>
              </div>
              <div className="button">
                <Link to="/catalog" className="btn rounded-5 px-4 py-2">
                  LIHAT CATALOG
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomePage;
