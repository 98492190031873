import { useState } from "react";
import "../../assets/styles/css/NavbarBottom.css";
import { BsList } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";

function NavbarBottom() {
  const [navbar, setNavbar] = useState(false);

  return (
    <section className="navbar-bottom d-md-none">
      <div className="nav-wrapper">
        <div
          className={`${
            navbar ? "nav-list-active" : "nav-list"
          } text-center shadow`}
        >
          <div className="nav-item mb-1">
            <a
              href="https://wa.wizard.id/e21b63"
              target="_blank"
              rel="noreferrer"
              className="nav-link"
            >
              <IoLogoWhatsapp className="fs-5" /> Order
            </a>
          </div>
          <div className="nav-item mb-2">
            <Link to="/features" className="nav-link">
              Features
            </Link>
          </div>
          <div className="nav-item mb-2">
            <Link to="/catalog" className="nav-link">
              Catalog
            </Link>
          </div>
          <div className="nav-item mb-2">
            <a href="/#question" className="nav-link">
              FAQ
            </a>
          </div>
          <div className="nav-item ">
            <a href="#contact" className="nav-link">
              Contact
            </a>
          </div>
        </div>
        <div className="nav-icon shadow" onClick={() => setNavbar(!navbar)}>
          <div className="icon text-center d-flex align-items-center justify-content-center py-3">
            <BsList />
          </div>
        </div>
      </div>
    </section>
  );
}

export default NavbarBottom;
