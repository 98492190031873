import AccordionQuestion from "./AccordionQuestion";

function Question() {
  return (
    <section className="question" id="question">
      <div className="container">
        <div className="row justify-content-center top d-block d-md-none mb-4">
          <div className="col-11 text-end">
            <div className="title-res-wrapper">
              <h1>
                Frequently <br />
                Asked <br />
                Question
              </h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-around bottom align-items-center">
          <div className="col-11 col-md-7 section-accordion">
            <AccordionQuestion />
          </div>
          <div className="col-11 col-md-5 section-title text-end d-none d-md-block">
            <div className="title-wrapper">
              <h1>
                Frequently <br />
                Asked <br />
                Question
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Question;
