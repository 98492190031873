import Navigasi from "../components/Navigasi/Navigasi";
import HomePage from "../components/HomePage/HomePage";
import Service from "../components/HomePage/Service";
import Caption from "../components/HomePage/Caption";
import Question from "../components/HomePage/Question";
import Collection from "../components/HomePage/Collection";
import Client from "../components/HomePage/Client";
import Footer from "../components/Footer/Footer";
import NavbarBottom from "../components/NavbarBottom/NavbarBottom";
import Whatsapp from "../components/Whatsapp/Whatsapp";

function Home() {
  return (
    <>
      <Navigasi />
      <HomePage />
      <Service />
      <Collection />
      <Caption />
      <Client />
      <Question />
      <Footer />
      <NavbarBottom />
      <Whatsapp />
    </>
  );
}

export default Home;
