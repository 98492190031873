import "../../assets/styles/css/Navbar.css";
import logo from "../../assets/img/logo.png";
import { useState } from "react";
import { Link } from "react-router-dom";

function Navigasi() {
  const [navbar, setNavbar] = useState(false);

  const backgroundChange = () => {
    if (window.scrollY >= 60) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", backgroundChange);

  return (
    <header className={navbar ? "header active" : "header"}>
      <nav>
        <div className="container">
          <div className="navbar-wrapper d-flex justify-content-center py-3">
            {/* Nav Left */}
            <div className="col-5 nav-left justify-content-evenly align-items-center d-none d-md-flex">
              <div className="nav-item text-center w-25">
                <Link to="/features" className="nav-link">
                  Features
                </Link>
              </div>
              <div className="nav-item text-center w-25">
                <Link to="/catalog" className="nav-link">
                  Catalog
                </Link>
              </div>
            </div>
            {/* Nav Left */}

            {/* Logo */}
            <div className="col-2 logo d-flex justify-content-center m-auto">
              <div className="logo-wrapper ">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            {/* Logo */}

            {/* Nav Right */}
            <div className="col-5 nav-right justify-content-evenly align-items-center d-none d-md-block d-none d-md-flex">
              <div className="nav-item text-center w-25">
                <a href="/#question" className="nav-link">
                  FAQ
                </a>
              </div>
              <div className="nav-item text-center w-25">
                <a href="#contact" className="nav-link">
                  Contact
                </a>
              </div>
            </div>
            {/* Nav Right */}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navigasi;
