import React from "react";

function CardCatalog(props) {
  const { image, name, basicPrice, basicLink, premiumPrice, premiumLink } =
    props;
  return (
    <>
      <div className="col-8 col-md-3 card p-0 mx-2 rounded-4 mb-3">
        <img src={image} className="card-img-top rounded-4" alt="mockup" />
        <div className="card-body">
          <h5 className="fst-italic heading-catalog">{name}</h5>
          <div className="link mb-2">
            <a
              href={basicLink}
              className="me-2"
              target="_blank"
              rel="noreferrer"
            >
              Basic <b>{basicPrice}</b>
            </a>
            |
            <a
              href={premiumLink}
              className="ms-2"
              target="_blank"
              rel="noreferrer"
            >
              Premium <b>{premiumPrice}</b>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardCatalog;
