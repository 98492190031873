import captionImage from "../../assets/img/gambar_satu.png";
import "../../assets/styles/css/HomePage.css";
import { Link } from "react-router-dom";

function Caption() {
  return (
    <section>
      <div className="caption-share">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-11 col-md-5 section-image mb-4 mb-md-0">
              <div className="image-wrapper mx-auto">
                <img src={captionImage} alt="img" />
              </div>
            </div>
            <div className="col-11 col-md-5 section-caption text-center text-md-start">
              <div className="caption-wrapper">
                <div className="text mb-4">
                  <h1 className="mb-3">Share...</h1>
                  <p>
                    Di era sekarang saatnya menggunakan undangan digital, maka
                    dari itu Undankin siap memberi undangan digital yang
                    praktis, memberi banyak fitur, serta desain yang simple,
                    minimalist, dan elegant.
                  </p>
                </div>
                <div className="button">
                  <Link to="/features" className="btn rounded-5 px-4 py-2">
                    MORE FEATURES...
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Caption;
