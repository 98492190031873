import React from "react";

function CardService(props) {
  const { title, desc } = props;
  return (
    <div className="col-10 col-md-3 card-wrapper border py-4 px-3 rounded-4 shadow">
      <h3>{title}</h3>
      <p className="mb-0">{desc}</p>
    </div>
  );
}

export default CardService;
